import React from "react"
import { Link } from "gatsby"
// import "../../styles/new-landing.css"
import "../../styles/welcomeMoofarm.css"
import BagIcon from "../../images/landing/bagimg.png"
import { Helmet } from "react-helmet"
import checkList from "../../images/welcomeMoofarm/check-list.webp"
let utmObj;
class Thanks extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            
        }
    }

    fbqTrack = (eventName, params = {}) => {
        if (typeof fbq === 'function') {
            window.fbq("trackCustom", eventName, { ...params });
            debugger;
            console.log('tracked')
        }
    }
    componentDidMount() {
        const queryString = window.location.search
        const paramsofquery = new URLSearchParams(queryString);
        if (paramsofquery.get('utm_source') && paramsofquery.get('utm_medium') && paramsofquery.get('utm_campaign') && paramsofquery.get('utm_content')) {
            utmObj = {
                'utm_source': paramsofquery.get('utm_source'),
                'utm_medium': paramsofquery.get('utm_medium'),
                'utm_campaign': paramsofquery.get('utm_campaign'),
                'utm_content': paramsofquery.get('utm_content')
            }
        } else {
            utmObj = {}
        }
        window.analytics.track("Visitors", { utmObj })
        let mobile = sessionStorage.getItem("CustMobileNumber")
        if (mobile) {
            let payload = {
                "contact": mobile
            };
            this.fbqTrack("lead_p0", payload);
            window.analytics.track("lead_p0", payload)
        }
    }

    render() {
        return (
            <div className="main-container">
                <Helmet>
                    <link
                        href="https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700&display=swap"
                        rel="stylesheet"
                    />
                    <link
                        rel="stylesheet"
                        href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css"
                    />
                    <link
                        rel="stylesheet"
                        type="text/css"
                        charset="UTF-8"
                        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
                    />
                    <link
                        rel="stylesheet"
                        type="text/css"
                        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
                    />
                    <link
                        rel="stylesheet"
                        href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
                    />
                    <script src="js/script.js"></script>
                    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
                    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
                </Helmet>
                <div className="container main-div">
                    <div className="top_banner bg-lightgreen h-70">
                        <div className="top_banner_view thanks-view">
                            <div>
                                <img src={checkList} />
                                <h2>धन्यवाद !</h2>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default Thanks;
